@import '../../../../styles/mixins';
@import '../../../../styles/variables';

.infoContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 16px;

  .titleContainer {
    display: flex;
    align-items: center;
    gap: 16px;
    color: $neutral900;

    .title {
      @include font(18px, 600, 120%);
    }

    &.button {
      cursor: pointer;

      &:hover {
        color: $action100;
      }
    }

    div {
      display: flex;

      svg {
        color: $action100;
      }
    }
  }

  .infoPopout {
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 8px 16px;
    background-color: $blue200;
    color: $action100;

    .infoText {
      @include font(16px, 400, 120%);
    }
  }
}

.carListContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-height: 40dvh;
  overflow-y: scroll;
  scrollbar-color: $blue400, transparent;

  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: $blue400;
  }

  .car {
    padding: 8px 16px;
    border: 1px solid $blue300;
    display: flex;
    align-items: center;
    gap: 8px;

    .carInfo {
      .carName {
        @include font(16px, 600, 120%);
        color: $neutral900;
        margin-bottom: 4px;
      }
      .carDescription {
        @include font(14px, 500, 130%);
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }

    .actionsContainer {
      display: flex;

      .deleteBtn,
      .goBtn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        cursor: pointer;
      }

      .deleteBtn {
        background-color: $red100;
        color: $neutral100;
      }

      .goBtn {
        background-color: $action100;
        color: $action200;
      }
    }
  }
}

.vinContainer {
  display: flex;
  margin-top: 8px;

  .vinInput {
    width: 100%;
  }

  & button {
    min-width: 0;
    padding: 0 !important;
    width: 47px;
    height: 47px;
  }
}

.btn {
  width: 100%;
  margin-top: 8px;

  button {
    width: 100%;
  }
}
