@import '../../../../styles/variables';

.checkbox {
  svg {
    height: 16px;

    path {
      color: $action100;
    }
  }
}
