$neutral100: rgb(255, 255, 255);
$neutral200: rgb(243, 243, 243);
$neutral300: rgb(232, 232, 232);
$neutral500: rgb(163, 163, 163);
$neutral600: rgb(117, 117, 117);
$neutral700: rgb(71, 71, 71);
$neutral800: rgb(37, 37, 37);
$neutral900: rgb(25, 25, 25);
$blue100: rgb(242, 245, 253);
$blue200: rgb(230, 236, 251);
$blue300: rgb(204, 217, 247);
$blue400: rgb(179 198 243);
$blue600: rgb(127, 159, 234);
$blue900: rgb(51, 102, 222);
$action100: rgb(0, 64, 214);
$action200: rgb(0, 255, 112);
$green200: rgb(230, 255, 241);
$green300: rgb(204, 255, 226);
$red100: rgba(216, 0, 39, 1);
$red200: rgba(216, 0, 39, 0.2);
$green100: rgb(242, 255, 248);
$shadow200: 0px 0px 16px 0px rgba(25, 25, 25, 0.12);

$article-card-shadow: rgba(17, 17, 26, 0.09);

$darken30: rgba(0, 0, 0, 0.3);
$darken80: rgba(25, 25, 25, 0.8);
$darken: rgba(25, 25, 25, 1);
