@import '../../../styles/mixins';
@import '../../../styles/variables';

.basketNavigationContainer {
  padding-top: 24px;
  padding-bottom: 24px;

  .basketNavigation {
    max-width: 1304px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .controlsContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .backButton {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
        width: 33%;

        .backLabel {
          @include font(16px, 500, 120%);
          color: $action100;
        }
      }

      .logo {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 33%;
      }

      .secureLogin {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        gap: 8px;
        width: 33%;

        .secureLabel {
          @include font(16px, 500, 120%);
          color: $action100;
        }
      }
    }

    .tabItemsContainer {
      margin-top: 16px;
      display: flex;
      justify-content: center;

      :global(.MuiTabs-indicator) {
        background-color: $action200;
      }

      .tabItem {
        width: 220px;
        color: $neutral900;
        @include font(14px, 600, 120%);
        text-transform: none;
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;

        &:global(.Mui-selected) {
          color: $action100 !important;
        }

        :global(.MuiTab-icon) {
          margin-bottom: 0;
        }

        &:global(.Mui-disabled) {
          opacity: 0.3;
        }
      }
    }
  }
}
