@import '../../../../styles/mixins';

.dialogTitle {
  margin: 0;
  font-weight: 600;
  font-size: 1.0625rem;
  line-height: 1.55556;
  flex: 0 0 auto;
  padding: 24px 24px 16px;
  font-family: inherit;

  @include bp(s) {
    font-size: 1.125rem;
  }
}

.dialogContent {
  flex: 1 1 auto;
  overflow-y : auto;
  padding: 0 24px;
  font-family: inherit;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.57143;
}

.dialogActions {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: end;
  justify-content: flex-end;
  flex: 0 0 auto;
  padding: 24px;
}