@import '../../../../styles/mixins';
@import '../../../../styles/variables';

.manufacturerCardContainer {
  width: 163.5px;
  height: 80px;
  padding: 16px 8px;
  background-color: $neutral300;
  display: flex;
  justify-content: center;
  align-items: center;

  @include bp(m) {
    width: 200px;
    height: 104px;
  }

  .title {
    @include font(28px, 500, 120%);
    text-align: center;
    color: $neutral900;

    @include bp(m) {
      @include font(38px, 500, 120%);
    }
  }

  .image {
    max-width: 100%;
    max-height: 100%;
  }
}
