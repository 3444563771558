@import '../../../styles/mixins';

.errorFallbackContainer {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;

  @include bp(s) {
    padding-left: 24px;
    padding-right: 24px;
  }

  @include bp(l) {
    max-width: 1200px;
  }

  .title {
    margin: 0 0 16px;
    font-family: inherit;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 1.5;
    text-align: center;

    @include bp(s) {
      font-size: 1.625rem;
    }

    @include bp(m) {
      font-size: 1.875rem;
    }

    @include bp(l) {
      font-size: 2rem;
    }
  }

  .subtitle {
    margin: 0;
    font-family: inherit;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    text-align: center;
  }

  .errorImage {
    width: 320px;
    height: auto;
    max-width: 100%;
    flex-shrink: 0;
    margin-top: 40px;
    margin-bottom: 40px;

    @include bp(s) {
      margin-top: 80px;
      margin-bottom: 80px;
    }

    img {
      width: 100%;
    }
  }
}
