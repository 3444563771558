@import '../../../../../styles/mixins';
@import '../../../../../styles/variables';

.topNavigationIconItem {
  display: flex;
  gap: 16px;
  cursor: pointer;
  align-items: center;

  &:hover {
    opacity: 0.8;
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    div {
      display: flex;
      color: $action100;
    }

    .badge {
      position: absolute;
      top: -6px;
      right: -12px;
      @include font(10px, 600);
      padding: 4px;
      height: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100px;
      background: $action200;
      color: $action100;
    }
  }

  .textContainer {
    display: flex;
    flex-direction: column;
    gap: 2px;

    .title {
      @include font(14px, 600, 120%);
      color: $neutral700;
    }

    .subtitle {
      @include font(12px, 400, 130%);
      color: $neutral500;
    }
  }
}
