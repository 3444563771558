@import '../../../../styles/mixins';
@import '../../../../styles/variables';

.footerColumnContainer {
  width: 238px;
  padding: 16px;

  .title {
    margin-bottom: 16px;
    @include font(18px, 600, 120%);
    color: $neutral900;
  }

  .itemsContainer {
    display: flex;
    flex-direction: column;
    width: fit-content;
    gap: 8px;
  }
}
