@import '../../../styles/mixins';
@import '../../../styles/variables';

.articleCard {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: $neutral100;
  transition:
    box-shadow 0.2s,
    transform 0.2s;
  min-width: 256px;
  max-width: 424px;

  &:hover {
    box-shadow: 0 0 16px 0px $article-card-shadow;
    transform: translateY(-12px);
  }

  .thumbnail {
    min-width: 0;
    width: 100%;
    aspect-ratio: 26.5 / 16;
    object-fit: cover;
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 16px 16px;
    height: 100%;

    .title {
      @include font(20px, 600, 120%);
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      line-clamp: 3;
      -webkit-box-orient: vertical;

      @include bp(m) {
        @include font(24px, 600, 120%);
        -webkit-line-clamp: 2;
        line-clamp: 2;
      }
    }

    .excerpt {
      @include font(16px, 400, 130%);
      color: $neutral700;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 5;
      line-clamp: 5;
      -webkit-box-orient: vertical;
      flex-grow: 1;
    }

    .button {
      div {
        display: flex;

        svg {
          width: 10.5px;
          height: 8px;
          transition: transform 0.15s;

          path {
            fill: $action100;
          }
        }
      }

      &:hover {
        svg {
          transform: translateX(8px);
        }
      }
    }
  }

  &.vertical {
    max-width: 648px;

    @include bp(l) {
      flex-direction: row;

      .thumbnail {
        max-width: 312px;
        flex-shrink: 0;
      }
    }
  }
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $neutral100;
  min-height: 400px;

  &.vertical {
    min-height: 200px;
  }
}
