@import '../../../styles/mixins';
@import '../../../styles/variables';

.menu {
  :global(.MuiPaper-root) {
    margin-top: 6px;
    width: 400px !important;
    background-color: $neutral100;
    border: 1px solid $blue300;
    border-radius: 0 !important;
  }

  :global(.MuiList-root) {
    padding: 16px !important;
  }
}

.modal {
  max-width: 400px !important;
  background-color: $neutral100;
  border: 1px solid $blue300;
  padding: 16px;
}
