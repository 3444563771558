@import '../../../styles/mixins';
@import '../../../styles/variables';

.announcementBar {
  padding: 4px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $action100;

  .announcementText {
    @include font(12px, 400, 130%);
    color: $action200;
  }
}
