@import '../../../styles/mixins';

.section {
  margin: 24px 0;

  @include bp(m) {
    margin: 32px 0;
  }

  .featuredBlogs {
    :global(.swiper) {
      overflow-y: initial;
      overflow-x: clip;
    }

    .cardContainer {
      display: flex;
      width: 256px;
      height: 100%;

      @include bp(s) {
        width: 324px;
      }

      @include bp(m) {
        width: 424px;
      }
    }

    .loadingContainer {
      grid-column: 1 / -1;
      width: 100%;
      margin-top: 16px;
      display: flex;
      justify-content: center;
    }
  }
}
