@mixin bp($point) {
  @if $point == s {
    @media (min-width: 600px) {
      @content;
    }
  } @else if $point == sm {
    @media (min-width: 750px) {
      @content;
    }
  } @else if $point == m {
    @media (min-width: 900px) {
      @content;
    }
  } @else if $point == ml {
    @media (min-width: 1050px) {
      @content;
    }
  } @else if $point == l {
    @media (min-width: 1200px) {
      @content;
    }
  } @else {
    @media (min-width: $point) {
      @content;
    }
  }
}

@mixin font($size, $weight, $line-height: normal) {
  font-family: 'Poppins', sans-serif;
  font-size: $size;
  font-style: normal;
  font-weight: $weight;
  line-height: $line-height;
}
