@import '../../../../styles/mixins';
@import '../../../../styles/variables';

.topNavigationContainer {
  width: 100%;
  padding: 16px 16px 0px;
  margin-bottom: 8px;

  @include bp(l) {
    margin-bottom: 0;
  }

  .innerContainer {
    max-width: 1320px;
    margin-left: auto;
    margin-right: auto;

    .upperPart {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .upperIconItems {
        display: flex;
        flex-direction: row;
        gap: 16px;
        justify-content: space-between;
        align-items: center;
      }
    }

    .searchItemsContainer {
      display: flex;
      flex-direction: row;
      gap: 24px;
      align-items: center;
      width: 100%;
      margin-top: 16px;
    }

    .partTypesContainer {
      display: flex;
      align-items: center;
      border-bottom: 1px solid $blue100;
      margin-top: 16px;

      .type {
        flex: 1 0 0;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        white-space: nowrap;
        padding: 8px;
        border-bottom: 1px solid transparent;
        margin-bottom: -1px;
        transition: border 0.2s;
        cursor: pointer;

        @include bp(970px) {
          padding: 0.5rem;
        }

        &:hover {
          border-bottom: 1px solid $action200;

          .typeTitle,
          .typeIcon {
            color: $action100;
          }
        }

        .typeTitle {
          @include font(12px, 400, 130%);
          color: $neutral700;
          transition: color 0.2s;
        }

        .typeIcon {
          width: 24px;
          height: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: $neutral700;
          transition: color 0.2s;
        }
      }
    }
  }
}
