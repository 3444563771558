@import '../../../styles/mixins';

.profileContainer {
  margin-top: 16px;
  margin-bottom: 40px;
  padding: 0 16px;

  .innerContainer {
    max-width: 1320px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-left: auto;
    margin-right: auto;

    @include bp(ml) {
      flex-direction: row;
    }
  }
}
