.attachmentBoxContainer {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;

  .innerContainer {
    width: 64px;
    height: 64px;
    flex-shrink: 0;
    border-radius: 10px;
    align-items: center;
    position: relative;
    display: inline-flex;
    justify-content: center;
    flex-direction: column;

    .image {
      cursor: pointer;

      img, svg {
        width: 60px;
        height: 60px;
        max-width: 100%;
        vertical-align: middle;
      }
    }

    .loader {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 10;
    }

    .closeIcon {
      position: absolute;
      top: 4px;
      right: 4px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      cursor: pointer;
      user-select: none;
      vertical-align: middle;
      appearance: none;
      font-family: inherit;
      text-align: center;
      font-size: 1.125rem;
      outline: 0;
      border: none;
      margin: 0;
      text-decoration: none;
      flex: 0 0 auto;
      border-radius: 50%;
      overflow: visible;
      transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
      padding: 2.8px;

      &:hover {
        opacity: 0.8;
      }

      svg {
        width: 12px;
        height: 12px;
      }
    }
  }

  .fileName {
    width: 60px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 11px;
    text-align: center;
  }
}
