@import '../../../styles/mixins';
@import '../../../styles/variables';

.wrapper {
  width: inherit;

  .buttonContainer {
    padding: 12px 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 0;
    @include font(16px, 500, 120%);

    &.primary {
      background-color: $action100;
      color: $action200;
    }

    &.secondary {
      background-color: $action200;
      color: $action100;
    }

    &.outline {
      background-color: #ffffff;
      color: $action100;
      border: 1px solid $action100;
    }

    &.contained {
      color: $action100;
      padding: 12px 0;

      &:hover {
        background-color: transparent;
      }

      &:focus-visible {
        outline: 2px solid $blue600;
      }
    }

    &.disabled {
      background-color: $neutral300;
      color: $neutral700;
    }

    &:hover {
      opacity: 0.8;
    }
  }
}
