@import '../../../styles/mixins';
@import '../../../styles/variables';

.sliderWrapper {
  display: flex;
  flex-direction: column;

  .cardsContainer {
    width: 100%;

    .slide {
      width: auto;
      height: auto;
    }
  }

  .buttonsContainer {
    margin-top: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 24px;

    .sliderButton {
      width: 32px;
      height: 32px;
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      min-width: 0;
      background: $action100;
      color: $action200;
      display: flex;

      &:hover {
        cursor: pointer;
        opacity: 0.8;
      }

      div {
        display: flex;
      }
    }
  }
}
