@import '../../../styles/mixins';
@import '../../../styles/variables';

.sectionContainer {
  padding: 24px 16px;

  @include bp(s) {
    padding: 32px 16px;
  }

  @include bp(m) {
    padding: 32px 16px;
  }

  &.accent {
    background-color: $neutral200;
  }

  .title {
    max-width: 1320px;
    margin: 0 auto 16px;
    @include font(26px, 500, 120%);
    text-align: center;
    color: $neutral900;

    @include bp(m) {
      margin: 0 auto 24px;
      @include font(38px, 500, 120%);
    }
  }

  .contentContainer {
    margin: 0 auto;
    max-width: 1320px;

   ul {
      padding-left: 40px;
    }
  }
}
