@import '../../../styles/mixins';
@import '../../../styles/variables';

.searchInputContainer {
  display: flex;
  flex-direction: row;
  height: 44px;
  width: 100%;

  .searchInputContainerInner {
    padding: 12px 24px;
    display: flex;
    flex-direction: row;
    gap: 8px;
    border: 1px solid $blue300;
    width: 100%;
    flex: 1 0 0;

    form {
      width: 100%;
      display: flex;
      align-items: center;
    }

    .input {
      @include font(14px, 400, 130%);
      border: none;
      flex: 1;
      width: 100%;
      min-width: 8rem;

      &:focus {
        border: none;
        outline: none;
      }
    }

    .needHelp {
      display: flex;
      white-space: nowrap;
      flex-direction: row;
      gap: 8px;
      @include font(14px, 400, 130%);
      color: $neutral500;
      cursor: pointer;

      div {
        color: $blue600;
      }
    }
  }

  .button {
    width: 44px;
    height: 44px;
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: $blue600;
    color: $action200;

    &:hover {
      opacity: 0.8;
    }

    div {
      display: flex;
    }
  }
}

.helpModal {
  padding: 16px;
  width: 100%;
  max-width: 600px !important;
  display: flex;
  flex-direction: column;

  .title {
    @include font(20px, 600, 120%);
    padding: 0 px;
    text-align: center;
    color: $neutral900;
    margin-bottom: 24px;
  }

  .infoContainer {
    width: 100%;
    display: flex;
    flex-direction: column;

    .info {
      display: flex;
      justify-content: space-between;
      gap: 32px;
      padding: 12px 0;
      color: $neutral500;
      width: 100%;

      & > p {
        @include font(16px, 500, 130%);
      }

      &:first-child {
        color: $neutral700;
      }

      &:not(:last-child) {
        border-bottom: 1px solid $blue200;
      }

      .infoValue {
        text-align: end;
      }
    }
  }
}
