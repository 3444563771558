@import '../../../styles/mixins';
@import '../../../styles/variables';

.ctaContainer {
  width: 100%;
  margin: 24px 0;
  padding: 0 16px;
  justify-content: center;

  @include bp(m) {
    margin: 32px 0;
  }

  .innerContainer {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    width: fit-content;
    scale: 1;

    @include bp(m) {
      flex-direction: row;
      max-width: 1320px;
      width: 100%;
      scale: 0.9;
    }

    @include bp(ml) {
      scale: 1;
    }

    .ctaImage {
      width: 174px;
      height: 142px;

      @include bp(m) {
        width: 312px;
        height: 252px;
        margin-top: 50px;
      }
    }

    .placeholderContainer {
      position: relative;

      .ctaPlaceholder {
        width: 300px;
        height: 145px;

        @include bp(s) {
          width: 343px;
          height: 150px;
        }

        @include bp(m) {
          width: 672px;
          height: 225px;
          margin-left: -30px;
        }
      }

      .textContainer {
        display: flex;
        flex-direction: column;
        gap: 8px;
        position: absolute;
        top: 30px;
        left: 30px;

        @include bp(s) {
          left: 40px;
        }

        @include bp(sm) {
          left: 50px;
        }

        @include bp(m) {
          gap: 16px;
          top: 50px;
          left: 120px;
        }

        .lineContainer {
          display: flex;
          flex-direction: row;
          gap: 10px;

          .firstLine {
            @include font(24px, 500, 120%);
            color: $action200;

            @include bp(m) {
              @include font(44px, 700, 120%);
            }

            &.buyNow {
              color: $neutral100;
            }
          }

          .secondLine {
            @include font(20px, 700, 120%);
            color: $action200;

            @include bp(m) {
              @include font(34px, 700, 120%);
            }

            &.cta {
              color: $neutral100;
            }
          }

          .buttonCta {
            width: 64px;
            height: 24px;
            @include font(9px, 500, 120%);

            @include bp(m) {
              width: auto;
              height: auto;
              margin-left: 22px;
              @include font(16px, 500, 120%);
            }
          }
        }
      }
    }
  }
}
