@import '../../../styles/mixins';
@import '../../../styles/variables';

.subscribeContainer {
  margin: 24px 0 16px;
  padding: 0 16px;

  @include bp(m) {
    margin: 32px 0 40px;
  }

  .inner {
    max-width: 1178px;
    margin-left: auto;
    margin-right: auto;
    padding: 24px;
    box-shadow: 0 0 16px 0 rgba(25, 25, 25, 0.12);
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
    width: 100%;
    background-color: $blue200;

    @include bp(m) {
      flex-direction: row;
      padding: 40px;
    }

    .textContainer {
      display: flex;
      flex-direction: column;
      gap: 8px;

      @include bp(m) {
        width: 50%;
      }

      .title {
        @include font(24px, 600, 120%);
        text-align: center;
        color: $neutral900;

        @include bp(m) {
          @include font(30px, 600, 120%);
          text-align: start;
        }

        strong {
          font-weight: inherit;
          color: $action100;
        }
      }

      .subtitle {
        @include font(16px, 400, 120%);
        text-align: center;
        color: $neutral700;

        @include bp(m) {
          text-align: start;
        }
      }
    }

    .actionContainer {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 10px;

      @include bp(m) {
        width: 50%;
      }

      .inputContainer {
        display: flex;
        flex-direction: column;
        row-gap: 16px;
        width: 100%;

        @include bp(sm) {
          flex-direction: row;
          flex: 1 0 0;
          height: 42px;
        }

        :global(.MuiFormControl-root) {
          width: 100%;

          :global(.MuiInputBase-root) {
            max-height: 44px !important;
            flex: 1;

            :global(.MuiOutlinedInput-notchedOutline) {
              border-color: $action100;

              @include bp(sm) {
                border-right: none;
              }
            }
          }

          :global(
              .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline
            ) {
            border-color: $red100 !important;
          }
        }

        .button {
          height: 44px;
          width: 100%;

          @include bp(m) {
            width: auto;
          }
        }
      }

      .agreementContainer {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: flex-start;

        :global(.MuiSvgIcon-root) {
          color: $action100;

          path {
            color: $action200;
          }
        }

        :global(.MuiTouchRipple-root) {
          color: $action100;
        }

        .explanation {
          @include font(12px, 400, 130%);
          color: $neutral500;

          a {
            margin-left: 4px;
            color: $action100;
          }
        }
      }
    }
  }
}
