@import '../../../../../styles/mixins';
@import '../../../../../styles/variables';

.totalAmountContainer {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  .amount {
    @include font(20px, 500, 120%);
    color: $action100;
  }

  .downIcon {
    color: $action100;
    display: flex;

    div {
      display: flex;
    }
  }
}
