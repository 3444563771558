@import '../../../../styles/mixins';
@import '../../../../styles/variables';

.sidebar {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  @include bp(700px) {
    flex-direction: row;
  }

  @include bp(ml) {
    max-width: 232px;
    flex-direction: column;
  }

  @include bp(l) {
    max-width: 312px;
    flex-direction: column;
  }

  .client {
    display: flex;
    align-items: start;
    gap: 16px;
    max-width: 375px;
    padding: 8px 0 8px;

    @include bp(ml) {
      padding: 0;
      max-width: auto;
      flex-direction: column;
    }

    .imageContainer {
      margin: 0 auto;
      padding: 0;

      .image {
        border-radius: 100%;
        width: 64px;
        height: 64px;
        border: none;
        padding: 0;

        @include bp(ml) {
          width: 152px;
          height: 152px;
        }
      }
    }

    .info {
      display: flex;
      flex-direction: column;
      gap: 8px;

      @include bp(ml) {
        text-align: center;
        align-items: center;
        width: 100%;
      }

      .name {
        padding-bottom: 8px;
        border-bottom: 1px solid $action200;

        & > p {
          @include font(16px, 500, 130%);
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }

      .clientNumber {
        display: flex;
        justify-content: start;
        gap: 16px;

        @include bp(ml) {
          justify-content: center;
        }

        & > p {
          @include font(16px, 600, 120%);
          white-space: nowrap;

          @include bp(l) {
            @include font(18px, 600, 120%);
          }
        }
      }

      .balance {
        display: flex;
        justify-content: start;
        gap: 16px;

        @include bp(ml) {
          justify-content: center;
        }

        & > p {
          @include font(16px, 500, 130%);
          white-space: nowrap;
          color: $neutral500;
        }
      }
    }
  }

  .navigation {
    display: flex;
    gap: 16px;
    align-items: baseline;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
    margin-left: auto;

    @include bp(ml) {
      flex-direction: column;
      gap: 0;
    }

    & > a,
    & > button,
    .moreBtn {
      display: flex;
      flex-direction: column;
      gap: 8px;
      justify-content: baseline;
      align-items: center;
      @include font(10px, 400, 130%);
      text-align: center;
      max-width: 64px;
      width: 100%;
      background-color: transparent;
      border: none;

      @include bp(ml) {
        max-width: 100%;
        flex-direction: row;
        justify-content: center;
        padding: 16px 0;
        border-bottom: 1px solid $action100;
        @include font(16px, 500, 130%);

        &:not(:first-of-type) {
          border: 1px solid $action100;
          border-top: none;
        }
      }

      & > div {
        width: 40px;
        height: 40px;
        padding: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $action100;
        background-color: $blue100;
        border: 1px solid $blue200;

        @include bp(ml) {
          width: 24px;
          height: 24px;
          padding: 0;
          border: none;
          background-color: transparent;
        }

        & > div,
        svg {
          width: 100%;
          height: 100%;
        }
      }

      &:hover,
      .active {
        svg {
          color: $action200;
        }

        @include bp(ml) {
          background-color: $action100;
          color: $action200;
        }
      }
    }

    .signoutBtn {
      @include bp(ml) {
        color: $neutral500;
        flex-direction: row;
        border: none;

        svg {
          color: $neutral500;
        }

        &:hover,
        .active {
          svg {
            color: $action200;
          }
        }
      }
    }

    .moreContainer {
      position: relative;

      .moreMenu {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 24px;
        padding: 8px;
        background-color: $action100;
        width: 40px;

        a,
        button {
          min-width: 0;
          padding: 0;

          div {
            display: flex;
            justify-content: center;
            align-items: center;
            color: $neutral100;

            svg {
              width: 24px;
              height: 24px;
            }
          }
        }
      }
    }
  }
}
