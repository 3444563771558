@import '../../../styles/mixins';

.title {
  max-width: 813px !important;
}

.cardsContainer {
  display: flex;
  justify-content: center;
  gap: 16px;
  flex-wrap: wrap;

  @include bp(m) {
    gap: 24px;
  }
}
