@import '../../../../../styles/mixins';
@import '../../../../../styles/variables';

.cartInfoContainer {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.5rem;
  white-space: nowrap;
  cursor: pointer;

  @include bp(m) {
    width: auto;
    height: 2.75rem;
    margin-right: 0;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
    background-color: $blue100;
  }

  @include bp(m) {
    min-width: 19.375rem;
    gap: 2rem;
  }
}

.menu {
  :global(.MuiPaper-root) {
    margin-top: 6px;
    width: 400px !important;
    background-color: $blue100;
    border: 1px solid $blue300;
    border-radius: 0 !important;
  }

  :global(.MuiList-root) {
    padding: 0 !important;
  }

  .cartItemContainer {
    display: flex;
    flex-direction: column;
    max-height: 50dvh;
    overflow-y: scroll;
    scrollbar-color: $blue400, transparent;

    &::-webkit-scrollbar {
      width: 3px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: $blue400;
    }

    .cartItem {
      display: flex;
      align-items: center;
      gap: 6px;
      padding: 16px;
      border-bottom: 1px solid $blue300;
      cursor: pointer;

      &:hover {
        background-color: $blue200;
      }

      img {
        width: 64px;
        height: 64px;
        object-fit: contain;
        border: 1px solid $blue200;
      }

      .cartItemInfo {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 12px;

        .cartItemName {
          @include font(16px, 600, 120%);
          color: $neutral900;
        }

        .cartItemPriceContainer {
          display: flex;
          gap: 16px;
          justify-content: space-between;
          color: $neutral900;

          .cartItemPrice {
            @include font(14px, 600, 120%);
          }

          .cartItemQuantity {
            @include font(14px, 400, 130%);
          }
        }
      }
    }
  }

  .summaryContainer {
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $neutral900;

    .summaryText {
      @include font(16px, 600, 120%);
    }

    .summaryPrice {
      @include font(18px, 600, 120%);
    }
  }

  .btnContainer {
    padding: 16px;

    .orderBtn {
      width: 100%;
    }

    .cartBtn {
      width: 100%;
      background-color: transparent !important;
      border: none !important;
    }
  }
}
