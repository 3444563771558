@import '../../../styles/mixins';
@import '../../../styles/variables';

.communityContainer {
  width: 100%;
  padding: 24px 16px;

  @include bp(m) {
    padding: 32px 16px;
  }

  .innerContainer {
    max-width: 1320px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 16px;

    @include bp(m) {
      flex-direction: row;
      gap: 24px;
    }

    .titleContainer {
      display: flex;
      flex-direction: column;
      gap: 8px;
      width: 100%;
      max-width: 538px;

      .title {
        @include font(24px, 500, 120%);
        color: $neutral900;

        @include bp(m) {
          @include font(34px, 500, 120%);
          max-width: 240px;
        }
      }

      .subtitle {
        @include font(18px, 400, 130%);
        color: $neutral700;
      }
    }

    .cardsContainer {
      display: flex;
      flex-direction: row;
      gap: 16px;
      flex-wrap: wrap;

      @include bp(m) {
        gap: 24px;
      }
    }
  }
}
