* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  text-size-adjust: 100%;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  scrollbar-gutter: stable;
  overflow: auto;
}

body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  line-height: 1.5;
  font-size: 1rem;
  font-family: 'Public Sans', sans-serif;
  font-weight: 400;
}

body:has(.MuiPopover-root, .MuiModal-root) {
  padding-right: 0 !important;
}

#root {
  width: 100%;
  height: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}
