@import '../../../../styles/mixins';
@import '../../../../styles/variables';

.dropdownItem {
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  padding: 4px 16px;
  border-bottom: 1px solid $blue300;
  background-color: $blue100;
  cursor: pointer;

  &.indent {
    padding: 4px 16px 4px 24px;
  }

  &.darken {
    background-color: $blue200;
  }

  &:hover,
  &.open {
    .contentContainer,
    .nameContainer {
      color: $action100 !important;
    }
  }

  .contentContainer {
    display: flex;
    align-items: center;
    gap: 8px;

    .nameContainer {
      display: flex;
      flex-direction: column;
      color: $neutral700;
      transition: color 0.15s;

      .name {
        @include font(16px, 500, 130%);
      }

      .description {
        @include font(12px, 400, 130%);
      }
    }

    .icon {
      width: 32px;
      height: 32px;
      object-fit: cover;
    }
  }

  .chevronContainer {
    margin-left: auto;
    display: flex;
    gap: 8px;

    .loader {
      width: 12px !important;
      height: 12px !important;
      color: $action100;
    }
  }

  .chevron,
  .chevronBack {
    div {
      display: flex;
      align-items: center;
      justify-items: center;
      color: $action100;
    }
  }

  .chevron {
    margin-left: auto;
  }

  .chevronBack {
    margin-right: 16px;
  }
}
