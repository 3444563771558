@import '../../../../styles/variables';

.passwordInputContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;

  .link {
    margin: 0;
    font-family: inherit;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.57143;
    text-decoration: none;
    color: inherit;
    align-self: flex-end;

    &:hover {
      text-decoration: underline;
    }
  }

  .passwordInput {
    .endAdornment {
      margin-right: -6px;

      & > button {
        color: $action100;
      }
    }
  }
}
