@import '../../../../../styles/mixins';
@import '../../../../../styles/variables';

.hamburgerIcon div {
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.drawer {
  :global(.MuiPaper-root) {
    width: 500px;
    max-width: calc(100vw - 32px);
    max-height: calc(100dvh - 48px);
    top: 24px;
    background: transparent !important;
    box-shadow: none !important;
    overflow: hidden;
  }

  :global(.MuiBackdrop-root) {
    background-color: $darken80 !important;
  }

  .closeBtnContainer {
    display: flex;
    justify-content: flex-end;

    .closeBtn {
      padding: 0;
      width: 24px;
      height: 24px;

      div {
        display: flex;
      }
    }
  }

  .content {
    max-height: calc(100% - 24px);

    .garageIcon {
      position: relative;

      div {
        width: 32px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          width: 100%;
          height: 100%;
        }
      }

      .carNumber {
        width: 17px;
        height: 12px;
        padding: 2px;
        background-color: $action200;
        display: flex;
        justify-content: center;
        align-items: center;
        @include font(10px, 600, 120%);
        color: $action100;
        border-radius: 100%;
        position: absolute;
        top: -4px;
        right: -5px;
      }
    }

    .myGarageContainer {
      background-color: $blue100;
      padding: 16px;
      display: flex;
      flex-direction: column;
      height: 100%;

      .garageListContainer {
        max-height: 100% !important;
        flex: 1;
        height: 100%;
      }
    }
  }
}
