@import '../../../../../styles/mixins';
@import '../../../../../styles/variables';

.item {
  width: auto;
  @include font(16px, 400, 120%);
  display: flex;
  align-items: center;
  color: $neutral900;

  &.clickableItem {
    &:hover {
      cursor: pointer;
      color: $action100;
    }
  }

  .icon {
    margin-right: 8px;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;

    div {
      display: flex;
    }
  }
}
