@import '../../../styles/mixins';
@import '../../../styles/variables';

.footerContainer {
  width: 100%;
  padding: 0 16px 16px;

  .innerContainer {
    max-width: 1320px;
    margin-left: auto;
    margin-right: auto;

    .columnsContainer {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      column-gap: 32px;
      margin-bottom: 16px;

      @include bp(m) {
        margin-bottom: 16px;
      }

      .columnContainer {
        display: flex;
        flex-direction: column;
        gap: 16px;
      }
    }

    .divider {
      border-color: $action100;
    }

    .companyInfoContainer {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-top: 8px;

      @include bp(m) {
        flex-direction: row;
      }

      .companyInfo {
        padding-top: 16px;
        padding-bottom: 16px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        max-width: 424px;

        .logo {
          max-width: 130px;
        }

        .companyShortInfo {
          @include font(14px, normal);
        }
      }

      .companyColumns {
        display: flex;
        flex-direction: column;
        column-gap: 20px;

        @include bp(m) {
          flex-direction: row;
        }
      }

      .countryFlag {
        font-size: 24px;
        margin-right: 8px;
      }
    }

    .copyrightContainer {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      margin-top: 14px;

      @include bp(m) {
        flex-direction: row;
      }

      .copyright {
        @include font(14px, 400, 130%);
        display: flex;
        align-items: center;
        gap: 8px;
      }
    }
  }
}
