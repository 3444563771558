@import '../../../../styles/mixins';

.buttonSelectInputContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;

  &.large {
    margin-bottom: 16px;

    .options {
      .option {
        padding: 20px;
        font-size: 0.875rem;
        line-height: 1.57143;

        svg {
          margin-bottom: 16px;
          width: 32px;
          height: 32px;
        }
      }
    }
  }

  &.small {
    margin-bottom: 0;

    .options {
      .option {
        padding: 10px;
        font-size: 0.75rem;
        line-height: 1.5;

        svg {
          margin-bottom: 2px;
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  .label {
    margin: 0;
    font-family: inherit;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.57143;
  }

  .options {
    display: flex;
    width: 100%;
    gap: 16px;
    flex-direction: column;

    @include bp(s) {
      flex-direction: row;
    }

    .option {
      width: 100%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      position: relative;
      box-sizing: border-box;
      cursor: pointer;
      user-select: none;
      vertical-align: middle;
      appearance: none;
      font-weight: 600;
      font-family: inherit;
      flex-direction: column;
      outline: 0;
      margin: 0;
      text-decoration: none;
      transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1);
      border-radius: 8px;
      border-width: 2px;
      border-style: solid;

      &.selectedOption {
        border-width: 2px;
      }

      svg {
        flex-shrink: 0;
        display: inline-flex;
      }
    }
  }
}
