@import '../../../styles/mixins';
@import '../../../styles/variables';

.modal {
  .loginForm {
    width: 100%;
    padding: 32px;
    display: flex;
    flex-direction: column;

    .title {
      @include font(20px, 600, 120%);
      text-align: center;
      color: $neutral900;
      margin-bottom: 24px;
    }

    .inputContainer {
      display: flex;
      flex-direction: column;
      gap: 24px;
      margin-bottom: 8px;
    }

    .checkBoxContainer {
      display: flex;
      align-items: center;
      margin-bottom: 24px;

      &.register {
        flex-direction: column;
        align-items: start;
        gap: 24px;
      }

      .actionText {
        @include font(12px, 400, 130%);
        color: $neutral500;

        &:last-child {
          margin-left: auto;
        }
      }
    }

    .alert {
      margin-bottom: 16px;
    }

    .loginBtn {
      width: 100%;

      & > button {
        width: 100%;
      }
    }

    .description {
      @include font(14px, 400, 130%);
      color: $neutral700;
      text-align: center;
      margin-top: 16px;
    }
  }

  .highlighted,
  .link {
    color: $action100 !important;
    text-decoration: underline;
    cursor: pointer;
  }
}
