@import '../../../../styles/mixins';
@import '../../../../styles/variables';

.socialCardContainer {
  width: 163.5px;
  height: 56px;
  padding: 8px;
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  cursor: pointer;
  background-color: $blue100;

  @include bp(m) {
    width: 236.6px;
    height: 72px;
    padding: 16px 24px;
  }

  &:hover {
    opacity: 0.8;
  }

  .name {
    @include font(18px, 400, 130%);
    color: $neutral900;
  }
}
